define(['app', 'simpleYoutube'], function(app, youtube) {
  var galleryDetails_video = function() {
    var obj = {};

    obj.select = {
      overlay: '.galleryDetails_videoOverlay',
      modal: '.galleryDetails_videoModal',
      exit: '.galleryDetails_videoExit',
      play: '.galleryDetails_videoPlay',
      player: '.galleryDetails_videoPlayer'
    };

    obj.attr = {
      component: 'data-component',
      video: 'data-options-video',
      show: 'data-show'
    };

    obj.init = function(el) {
      obj.el = el;
      obj.parent = obj.findParent(el);
      obj.play = el.querySelector(obj.select.play);
      obj.overlay = obj.parent.querySelector(obj.select.overlay);
      obj.modal = obj.parent.querySelector(obj.select.modal);
      obj.exit = obj.parent.querySelector(obj.select.exit);
      obj.player = obj.parent.querySelector(obj.select.player);
      obj.video = el.getAttribute(obj.attr.video);

      obj.play.addEventListener('click', obj.open, false);
      obj.exit.addEventListener('click', obj.close, false);
      obj.overlay.addEventListener('click', obj.close, false);
    };

    obj.findParent = function(el) {
      if (!el || document.body === el) {
        return null;
      }

      if (el.getAttribute(obj.attr.component) === 'galleryDetails') {
        return el;
      }

      return obj.findParent(el.parentNode);
    };

    obj.open = function() {
      obj.overlay.setAttribute(obj.attr.show, 'true');

      obj.player = new youtube(obj.player, {
        video: obj.video,
        className: obj.select.player.substr(1),
        replace: true,
        autoplay: true,
        showInfo: false,
        disableFullscreen: true,
        noLogo: true
      });

      app.publish('galleryDetails/lock');
    };

    obj.close = function() {
      obj.overlay.setAttribute(obj.attr.show, 'false');
      obj.modal.removeChild(obj.player);

      obj.player = document.createElement('span');
      obj.modal.appendChild(obj.player);

      app.publish('galleryDetails/unlock');
    };

    return obj;
  };

  return galleryDetails_video;
});
